<template>
  <div>
    <div class="wz">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="box">
      <el-row :gutter="20">
        <el-col :span="8">
          <div v-if="!isEdit" style="width: 100%; margin-bottom: 30px">
            <el-button
              type="primary"
              style="margin: 0px; float: left"
              size="small"
              @click="toEdit"
              class="mybotton"
              >编辑</el-button
            >
            <div style="width: 100%; display: flex; justify-content: center">
              <el-avatar :size="100" :src="userInfo.headImg"></el-avatar>
            </div>
            <el-divider></el-divider>
            <span
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 40px;
              "
              >Admin - {{ userInfo.account }}</span
            >
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 40px;
              "
            >
              昵称:{{ userInfo.userName }}
            </div>
            <!-- <el-divider></el-divider> -->
          </div>
          <div v-else class="add">
            <el-form
              ref="userForm"
              :rules="rules"
              :model="userForm"
              label-width="120px"
            >
              <el-form-item label="头像" prop="url">
                <upload-img
                  :modeldata="userForm"
                  @changeurl="getimgurl"
                  style="margin-top: 15px"
                ></upload-img>
              </el-form-item>
              <!-- <el-form-item v-if="isShowPwd" label="密码" prop="passWord">
                <div v-if="isShowPwd">
                  <el-input
                    v-model="userForm.passWord"
                    style="width: 334px"
                  ></el-input>
                  <el-button
                    type="primary"
                    style="margin-left: 10px"
                    size="small"
                    @click="
                      isShowPwd = false;
                      userForm.passWord = '';
                    "
                    >返回</el-button
                  >
                </div>
                <el-button
                  v-else
                  type="primary"
                  size="small"
                  @click="isShowPwd = true"
                  >去修改</el-button
                >
              </el-form-item> -->

              <el-form-item v-if="isShowPwd" label="密码" prop="passWord">
                <div>
                  <el-input
                    v-model="userForm.passWord"
                    style="width: 334px"
                  ></el-input>
                  <el-button
                    type="primary"
                    style="margin-left: 10px"
                    size="small"
                    @click="
                      isShowPwd = false;
                      userForm.passWord = '';
                    "
                    >返回</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item v-else label="密码" prop="">
                <el-button type="primary" size="small" @click="isShowPwd = true"
                  >去修改</el-button
                >
              </el-form-item>

              <el-form-item label="昵称" prop="userName">
                <el-input
                  v-model="userForm.userName"
                  style="width: 400px"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  v-preventReClick
                  type="primary"
                  @click="onSubmit('userForm')"
                  >提交</el-button
                >
                <el-button @click="toEdit">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import _api from "@/api/index";
import apiUrl from "@/api/public";
let checkpassword = (rule, value, cb) => {
  // const regPassword = /^[a-z0-9]{6,16}$/
  const regPassword = /^[0-9a-zA-Z]{6,12}$/g;
  if (regPassword.test(value)) {
    return cb();
  }
  cb(new Error("密码只能由6-12位字母或数字组成"));
};
export default {
  data() {
    return {
      userForm: {
        url: "",
        userName: "",
        passWord: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入招聘会名称", trigger: "blur" },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkpassword, trigger: "blur" },
        ],
      },
      userInfo: {},
      isEdit: false,
      isShowPwd: false,
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
      this.userInfo = userInfo;
      this.userForm.userName = userInfo.userName;
      this.userForm.url = userInfo.headImg;
    },
    getimgurl(data) {},
    onSubmit(formName) {
      const regPassword = /^[0-9a-zA-Z]{6,12}$/g;
      let result = regPassword.test(this.userForm.passWord);
      console.log(result, this.userForm.passWord.length);
      if (result || !this.userForm.passWord) {
        console.log(333);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            _api
              .post(apiUrl.makeInfoOrPassWord, {
                userName: this.userForm.userName,
                password: this.userForm.passWord,
                headImg: this.userForm.url,
              })
              .then((res) => {
                if (res.success) {
                  this.$message.success("修改信息成功！");
                  if (
                    !this.userForm.passWord ||
                    this.userForm.passWord === ""
                  ) {
                    let user = this.userInfo;
                    user.userName = this.userForm.userName;
                    user.headImg = this.userForm.url;
                    window.localStorage.setItem(
                      "userInfo",
                      JSON.stringify(user)
                    );
                    this.userInfo = JSON.parse(
                      window.localStorage.getItem("userInfo")
                    );
                    this.isEdit = !this.isEdit;
                    this.isShowPwd = false;
                    this.userForm.passWord = "";
                  } else {
                    window.localStorage.setItem("token", JSON.stringify(""));
                    window.localStorage.setItem("userInfo", JSON.stringify(""));
                    this.$router.push({ path: "/adminLogin" });
                  }
                }
              })
              .catch((err) => {
                this.$message.error(err);
              });
          } else {
            this.$message.error("表单未填写完整，请检查！");
            return false;
          }
        });
      } else {
        this.$message.error("密码只能由6-12位字母或数字组成");
      }
    },
    toEdit() {
      this.isEdit = !this.isEdit;
      this.isShowPwd = false;
      this.userForm.passWord = "";
      this.userForm.userName = this.userInfo.userName;
      this.userForm.phone = this.userInfo.phone;
      this.userForm.url = this.userInfo.headImg;
    },
  },
};
</script>
<style scoped>
.add {
  width: 900px;
}
</style>
